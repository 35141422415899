import BaseService from "@client.services/baseService";

export default class UserLogService extends BaseService {
  async getAll({ limit, offset, startDate, endDate, search, searchOn }) {
    console.log("Params sent to userLogs API:", { limit, offset, startDate, endDate, search, searchOn });
  
    const response = await super.post("", {
      query: `query($pagination: PaginationInputType) {
          userLogs(pagination: $pagination) {
            count
            results {
              Id
              type
              userId
              userFullName
              ipAddress
              createdAt
              updatedAt
              description
            }
          }
        }`,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  
    console.log("API Response from userLogs:", response); 
    return response;
  }

  async create(userLog) {
    return super.post("", {
      query: `mutation($fields: UserLogsInputType!) {
            createUserLog(fields: $fields) {
              message
              userLog {
                Id
                description
                userFullName
              }
            }
          }`,
      variables: {
        fields: {
          userId:userLog.userId,
          ipAddress:userLog.ipAddress|| null,
          description:userLog.description|| null,
          type:userLog.type,
          userFullName:userLog.userFullName || null,
        },
      },
    });
  }
}
  