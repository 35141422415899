import BaseService from "@client.services/baseService";

export default class DashboardService extends BaseService {
  async getSystemLogs() {
    return super.post("", {
      query: `query {
                fetchSystemLogs {
                  count
                  results {
                    createdAt
                    deletedAt
                    description
                    icon
                    isActive
                    isDeleted
                    lookupId
                    title
                    unitId
                    unitType
                    updatedAt
                  }
                }
      }`,
      variables: {},
    });
  }

  async getDashboardBoxes() {
    return super.post("", {
      query: `query {
        dashboardBoxes {
          activeCampaigns
          dailyAdPlays
          dailyAdViews
          dailyBudgetSpent
        }
      }`,
    });
  }
}
