import Loader from "@client.components/Loader";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TimelineItem = ({ item, className, onClick, isSelected }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [renderAsImage, setRenderAsImage] = useState(false); // Determines whether to fallback to image

  const src =
    item.campaignMediaUrl === ""
      ? "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4"
      : item.campaignMediaUrl;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading) {
        setRenderAsImage(true); // Switch to image if video loading takes too long
      }
    }, 2000); // 5-second timeout

    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <div className={classNames(
      "timeline-item", 
      isSelected ? "selected" : "", 
      item.status !== 5 ? 'timeline-item--disabled' : ''
    )} onClick={onClick}>
      {src && (
        <div className="media-wrapper">
          {loading && <Loader loading={true} />}
          {renderAsImage ? (
            <img
              src={src}
              alt="Fallback media"
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
              width='100%'
            />
          ) : (
            <video
              muted
              autoPlay={false}
              loop={false}
              preload="metadata"
              onLoadedMetadata={() => setLoading(false)}
              onError={() => setRenderAsImage(true)} // Fallback to image on error
              width='100%'
            >
              <source src={`${src}#t=1`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {isSelected && (
            <div className="img-badge">
              {t("components.UserAssets.selected")}
            </div>
          )}
        </div>
      )}
      <div className="timeline-frame">
        <div className="track-segment" />
        {/* <div className="track-separator" /> */}
      </div>
      <div className={"time-range"}>
        <span>
          {moment(item.startDate).utc().local().format("MM/DD/YYYY HH:mm:ss")}
        </span>
        <span>
          {moment(item.endDate).utc().local().format("MM/DD/YYYY HH:mm:ss")}
        </span>
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  item: PropTypes.shape({
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    afterMediaUrl: PropTypes.string,
    campaignMediaUrl: PropTypes.string,
    status: PropTypes.number,
  }).isRequired,
  isSelected: PropTypes.boolean,
};

export default TimelineItem;
