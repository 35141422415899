import { get } from "lodash";

const getResponseError = (action) => {
  try {
    return JSON.parse(action.error.message);
  } catch (e) {
    return null;
  }
};

const getGraphqlResponseError = (action) => {
  return get(action, "payload.errors", null);
};

export { getResponseError, getGraphqlResponseError };
