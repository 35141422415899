import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@client.components/SvgIcon";
import "./WelcomeCard.scss";

const WelcomeCard = ({ children }) => {
  return (
    <div className="welcome-card">
      {children}
      <SvgIcon name="welcome" />
    </div>
  );
};

WelcomeCard.propTypes = {
  children: PropTypes.node,
};

export default WelcomeCard;
