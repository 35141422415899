import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import LoginPage from "@client.pages/Login";
import ForgotPasswordPage from "@client.pages/ForgotPassword";
import ProfileChangePasswordPage from "@client.pages/ProfileChangePassword";
import LogoutPage from "@client.pages/Logout";
import AuthorizedLayout from "@client.components/AuthorizedLayout";
import UnauthorizedLayout from "@client.components/UnauthorizedLayout";
import RequireAuth from "@client.components/RequireAuth";
import Pages from "@client.enums/pages";
import Loader from "@client.components/Loader";
import "./i18n";
import RegisterUser from "@client.pages/RegisterUser";
import DataAnalyzer from "@client.pages/DataAnalyzer/DataAnalyzer";

const AppRoutes = () => {
  const { i18n } = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    const defaultLanguge = "en";
    const existingLanguges = [defaultLanguge];
    let languageToUse = existingLanguges.find((l) => l === i18n.language);
    if (!languageToUse) {
      languageToUse = existingLanguges.find((l) => i18n.language.startsWith(l));
    }

    const language = languageToUse || defaultLanguge;
    import(`@client.i18n/${language}.json`)
      .then((translations) => {
        i18n.changeLanguage(language);
        i18n.addResourceBundle(
          language,
          "translation",
          translations.default,
          true,
          true
        );
        setTranslationsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading translations:", error);
      });
  }, [i18n]);

  if (!translationsLoaded) {
    return (
      <div className="app page-loading">
        <Loader loading={true} />
      </div>
    );
  }
  return (
    <div className="app">
      <Routes>
        <Route element={<UnauthorizedLayout />}>
          <Route path={Pages.Login} element={<LoginPage />} />
          <Route path={Pages.RegisterUser} element={<RegisterUser />} />
          <Route path={Pages.ForgotPassword} element={<ForgotPasswordPage />} />
          <Route path="*" element={<LoginPage />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route element={<AuthorizedLayout withNavBar={true} />}>

            <Route
              path={Pages.ProfileChangePassword}
              element={<ProfileChangePasswordPage />}
            />
            <Route
              path={`${Pages.ProfileChangePassword}/:profileId`}
              element={<ProfileChangePasswordPage />}
            />

            <Route path={Pages.Logout} element={<LogoutPage />} />
            <Route path={Pages.DataAnalyzer} element={<DataAnalyzer />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;
