import BaseService from "@client.services/baseService";

export default class CampaignService extends BaseService {
  async getAll(advertiserId) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchUserCampaigns(id: $lookupId) {
          displayName
          totalViews
          totalSpent
          status
          lookupId
          totalPlays
          perPlayCalculation
          file {
            locationPath
          }
          endDate
          startDate
          isActive
        }
      }`,
      variables: {
        lookupId: advertiserId,
      },
    });
  }



  async updateCampaign(model) {
    return super.post("", {
      query: `mutation($id: String, $fields: CampaignInputType){
      updateCampaign(id: $id, fields: $fields){
        message
        campaign{
          Id
          status
          startDate
          endDate
        }
      }
    }`,
      variables: {
        id: model.Id,
        fields: { 
          status: model.status  || "", // Default empty string if value is null/undefined
        },
      },
    });
  }
  

  async getAllCampaigns({
    limit, offset, search, searchOn, startDate, endDate,
  }) {
    console.log("From service", startDate, endDate);
    return super.post("", {
      query: `query($pagination: PaginationInputType){
	campaigns(pagination: $pagination){
		count
		results{
			Id
			advertiserId
			budgetDaily
			budgetTotal
			campaignName
			createdAt
			creatorAccountId
			creatorUserId
      startDate
			endDate
			geofenceZoneIds
			isActive
			isDraft
			mediaId
			paymentId
			status
			target
			updatedAt
		}
	}
}`, variables: {
        accountId: "",
        pagination: {
          limit,
          offset,
          search,     
          searchOn,   
          startDate: startDate ? new Date(startDate).toISOString().slice(0, 10) : null,
          endDate: endDate ? new Date(endDate).toISOString().slice(0, 10) : null,
        },
        // isOnline,
      },
    });
  }


  async getAllCampaigns2({ endDate, startDate, status, limit, offset }) {
    return super.post("", {
      query: `query(
        $endDate: String
        $startDate: String
        $status: Int
        $limit: Int
        $offset: Int
      ) {
         fetchCampaigns(
          endDate: $endDate
          limit: $limit
          offset: $offset
          startDate: $startDate
          status: $status
          ) {
          count
          results {
            lookupId
            budgetAmount
            notes
            budgetLimit
            createdAt
            currentBalance
            deletedAt
            displayName
            endDate
            status
            isActive
            file {
              locationPath
            }
            totalPlays
            totalDisplays
            totalSpent
            totalViews
            account {
              companyName
            }
          }
        }
      }`,
      variables: {
        endDate,
        startDate,
        status,
        limit,
        offset,
      },
    });
  }


  async getById(id) {
    return super.post("", {
      query: `query($id: String){
	campaign(id: $id){
	advertiserId
	budgetDaily
	budgetTotal
	campaignName
	createdAt
	creatorAccountId
	creatorUserId
	endDate
	geofenceZoneIds
	Id
	isActive
	isDraft
	mediaId
	paymentId
	startDate
	status
	target
	updatedAt
	}
}`,
      variables: {
        id,
      },
    });
  }

  
  async getById2(id) {
    return super.post("", {
      query: `query($lookupId: String){
        fetchCampaign(id: $lookupId) {
          lookupId
          budgetAmount
          currentBalance
          displayName
          status
          totalSpent
          totalViews
          selectedDays
          endDate
          totalPlays
          startDate
          file {
            locationPath
          }
          locations {
            lookupId
            displayName
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getPerformance(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignCaptures(lookupId: $lookupId) {
          createdAt
          latitude
          longitude
          views
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }
  async getMonitoring(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignMonitoring(lookupId: $lookupId) {
          campaign{
            lookupId
            displayName
          }
          totalPlays
          totalViews
          totalSpent
          graphData{
            date
            plays
            views
            spent
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getStatisticOverview(id, dateFrom, dateTo) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            date: "11/15/2023",
            plays: 100,
            views: 230,
            spent: 20,
          },
          {
            date: "11/16/2023",
            plays: 111,
            views: 240,
            spent: 23,
          },
          {
            date: "11/17/2023",
            plays: 50,
            views: 100,
            spent: 10,
          },
          {
            date: "11/18/2023",
            plays: 500,
            views: 800,
            spent: 40,
          },
        ]);
      }, 500);
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getEstimation(data) {
    return new Promise((resolve) => {
      resolve({
        plays: 1000,
        pricePerView: 0.3,
        price: 300,
      });
    });
  }

  async getTargets() {
    return Promise.resolve([
      {
        id: "1",
        name: "Google Search",
      },
      {
        id: "2",
        name: " Attract Customer to Location",
      },
      {
        id: "3",
        name: "Brand Awareness",
      },
      {
        id: "4",
        name: "Something Else",
      },
    ]);
  }

  async getTimeSlots() {
    return Promise.resolve([
      {
        id: "1",
        name: "All Day",
      },
      {
        id: "2",
        name: "Morning - 7am- 11am",
      },
      {
        id: "3",
        name: "Lunch - 11am- 2pm",
      },
      {
        id: "4",
        name: "Afternoon - 2pm - 5pm",
      },
      {
        id: "5",
        name: "Dinner - 5pm - 8pm",
      },
      {
        id: "6",
        name: "Evening - 8pm - 10pm",
      },
      {
        id: "7",
        name: "Night - 10pm - 7am",
      },
    ]);
  }



  async saveCampaign(campaign) {
    return super.post("", {
      query: `mutation (
        $campaignName: String,
        $advertiserId: String,
        $startDate: String,
        $endDate: String,
        $target: Int,
        $mediaId: String,
        $budgetType: Int,
        $budgetAmount: Float,
        $geofenceType: Int,
        $geofenceRadiusLatitude: Float,
        $geofenceRadiusLongitude: Float,
        $geofenceRadiusRange: Int,
        $geofenceZoneIds: [String],
        $paymentId: String,
        $isDraft: Boolean
      ) {
        customCreateCampaign(
          campaignName: $campaignName,
          advertiserId: $advertiserId,
          startDate: $startDate,
          endDate: $endDate,
          target: $target,
          mediaId: $mediaId,
          budgetType: $budgetType,
          budgetAmount: $budgetAmount,
          geofenceType: $geofenceType,
          geofenceRadiusLatitude: $geofenceRadiusLatitude,
          geofenceRadiusLongitude: $geofenceRadiusLongitude,
          geofenceRadiusRange: $geofenceRadiusRange,
          geofenceZoneIds: $geofenceZoneIds,
          paymentId: $paymentId,
          isDraft: $isDraft
        ) {
          message
          campaign {
            campaignName
            startDate
            endDate
            advertiserId
            creatorAccountId
            creatorUserId
            target
            mediaId
            budgetTotal
            budgetDaily
            geofenceZoneIds
            paymentId
            isDraft
            status
          }
        }
      }`,
      variables: campaign.fields,
    });
  }

  async getLocations() {
    return super.post("", {
      query: `query {
        fetchLocations{
          lookupId
          createdAt
          isActive
          polygon
          pricePerView
          displayName
          
        }
      }`,
    });
  }

  async getActiveLocations() {
    return super.post("", {
      query: `query {
        campaignLocations {
          lookupId
          polygon
        }
      }`,
    });
  }

  async getActiveHeatmaps() {
    return super.post("", {
      query: `query {
        fetchActiveCampaignHeatmap {
          lookupId
          device {
            allTelemetry {
              lat
              lng
            }
          }
        }
      }`,
    });
  }

  async getHeatmap(lookupId) {
    return super.post("", {
      query: `query($lookupId: String) {
        fetchCampaignHeatmap(id: $lookupId) {
          latitude
            longitude
        }
      }`,
      variables: {
        lookupId: lookupId,
      },
    });
  }
}
