const config = {
  apiUrl: process.env.REACT_APP_API_BASE_URL,
  debug: true,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  dateFormat: process.env.REACT_APP_DATE_FORMAT,
  timeFormat: process.env.REACT_APP_TIME_FORMAT,
  dateTimeFormat: `${process.env.REACT_APP_DATE_FORMAT} ${process.env.REACT_APP_TIME_FORMAT}`,
  dateTimeSecondsFormat: `${process.env.REACT_APP_DATE_FORMAT} ${process.env.REACT_APP_TIME_FORMATSECONDS}`,
  dateTimeSecondsFormatReversed:`${process.env.REACT_APP_TIME_FORMATSECONDS} - ${process.env.REACT_APP_DATE_FORMAT}`,
  uploadUrl: process.env.REACT_APP_API_BASE_UPLOAD_URL,
  downloadUrl: process.env.REACT_APP_API_BASE_DOWNLOAD_URL,
  maxFileSizeInMb: process.env.REACT_APP_MAX_FILE_SIZE_IN_MB,
};

export default config;
