import Title from "@client.components/Title";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FilterBar from "./FilterBar";
import Viewer from "./Viewer";
import ServiceProvider from "@client.services/provider";
import Map from "./Map";
import AgeChart from "./AgeChart";
import GoogleMap from "@client.components/GoogleMap";
import Loader from "@client.components/Loader";
import Summary from "./Summary";
import "./DataAnalyzer.scss";
import NoDataPlaceholder from "@client.components/NoDataPlaceholder";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#AF19FF",
  "#FF6392",
  "#FF3D1F",
  "#9FE6B9",
  "#FFBF00",
  "#6A0572",
];

const Statistics = ({analyzerData}) => {
  const { t } = useTranslation();

  if (!analyzerData || !analyzerData?.peopleDetected) {
    return <div className="statistics-no-data-container"><NoDataPlaceholder /></div>
  }

  // Extracting data
  const {
    ageGraph = [],
    totalImpressions = 0,
    totalViews = 0,
    peopleDetected = 0,
  } = analyzerData;

  const peopleMale = ageGraph.reduce((sum, { male }) => sum + male, 0);
  const peopleFemale = ageGraph.reduce((sum, { female }) => sum + female, 0);
  const totalViewers = peopleMale + peopleFemale || 1; // Prevent division by zero

  // Gender Pie Chart Data
  const genderPieChartData = [
    ...(parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)) ?
      [{
        name: t("pages.DataAnalyzer.gender.male"),
        qty: peopleMale,
        value: parseFloat(((peopleMale / totalViewers) * 100).toFixed(2)),
      }] : []),
    ...(parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)) ?
      [{
        name: t("pages.DataAnalyzer.gender.female"),
        qty: peopleFemale,
        value: parseFloat(((peopleFemale / totalViewers) * 100).toFixed(2)),
      }] : []),
  ];

  const ageRangePieData = ageGraph.map(({ age, male, female }) => {
    const qty = (male || 0) + (female || 0);
    return {
      name: `Age ${age}`,
      qty,
      value: parseFloat(((qty / peopleDetected) * 100).toFixed(2)) || 0,
    };
  });

  const pieChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, qty, value } = payload[0].payload;
      return (
        <div className="pie-chart-tooltip">
          <p><b>{name}</b></p>
          <p>{t("pages.DataAnalyzer.quantity")}: {qty}</p>
          <p>{t("pages.DataAnalyzer.percentage")}: {value}%</p>
        </div>
      );
    }
    return null;
  };

  const isGenderPieChartDataEmpty = !(peopleMale + peopleFemale)

  return (
    <>
      <div className='gender-map-row'>
        <div className="data-analyzer-chart">
          <div className="chart-title">
            {t("pages.DataAnalyzer.map.title")}
          </div>
          <Map
            locations={analyzerData?.playLocations}
          />
        </div>
        {isGenderPieChartDataEmpty ? false : (
          <div className="data-analyzer-chart">
            <div className="chart-title">{t("pages.DataAnalyzer.demographics")}</div>
            <div className="demographics-charts">
              {/* Gender Pie Chart */}
              <ResponsiveContainer width="50%" height={400}>
                <PieChart>
                  <Pie
                    data={genderPieChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#8884d8"
                    label={(entry) => entry.name}
                  >
                    {genderPieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip content={pieChartTooltip} />
                </PieChart>
              </ResponsiveContainer>

              {/* Age Range Pie Chart */}
              <ResponsiveContainer width="50%" height={400}>
                <PieChart>
                  <Pie
                    data={ageRangePieData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#8884d8"
                    label={(entry) => entry.name}
                  >
                    {ageRangePieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip content={pieChartTooltip} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
      </div>
      <AgeChart data={analyzerData} />
    </>
  );
};

Statistics.propTypes = {
  analyzerData: PropTypes.shape({
    ageGraph: PropTypes.arrayOf(
      PropTypes.shape({
        age: PropTypes.number.isRequired,
        male: PropTypes.number,
        female: PropTypes.number,
      })
    ),
    totalImpressions: PropTypes.number,
    totalViews: PropTypes.number,
    peopleDetected: PropTypes.number,
    totalPeopleDetected: PropTypes.number,
    playLocations: PropTypes.any,
  }),
};

export default Statistics;
