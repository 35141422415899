import BaseModel from "./base";
import { get } from "lodash";

class ChangePasswordModel extends BaseModel {
  static passwordMinLength = 8;
  static passwordMaxLength = 32;
  static passwordUppercase = /^(?=.*[A-Z]).+$/;
  static passwordNumber = /^(?=.*\d).+$/;
  static passwordFormat = /^(?=.*[A-Z])(?=.*\d).{8,32}$/;

  constructor(user = {}, msg) {
    super(msg);

    this.oldPassword = get(user, "oldPassword", "");
    this.newPassword = get(user, "newPassword", "");
    this.confirmNewPassword = get(user, "confirmNewPassword", "");

    this.validationMsgs = msg;
    this.validationRules = {
      oldPassword: {
        presence: { allowEmpty: false, message: msg.oldPassword },
      },
      newPassword: {
        format: {
          pattern: ChangePasswordModel.passwordFormat,
          flags: "i",
          message: msg.newPassword,
        },
      },
      confirmNewPassword: {
        passwordConfirmation: {
          field: "newPassword",
          message: msg.passwordNotEqual,
        },
      },
    };
  }
}

export default ChangePasswordModel;
