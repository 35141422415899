import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "@client.components/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import Pages from "@client.enums/pages";

export const NavigationSection = ({
  items,
  title,
  dropdownItems,
  dropdownTitle,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className="navigation-sections">
      <div className="title">{t(title)}</div>
      <div className="top-bar-items">
        {items.map((item) => (
          <NavLink
            key={item.name + item.url}
            to={item.url}
            title={t(item.name)}
            className={(navData) => {
              return navData.isActive ? "top-bar-item active" : "top-bar-item";
            }}
          >
            {item.icon.prefix ? (
              <FontAwesomeIcon className="fa-lg faicon" icon={item.icon} />
            ) : (
              <Icon name={item.icon} />
            )}

            {t(item.name)}
          </NavLink>
        ))}
      </div>
      {dropdownItems && dropdownItems.length > 0 && (
        <div className="dropdown-nav">
          <div className="campaign-container">
            <div className="dropdown-title title" onClick={toggleDropdown}>
              {t(dropdownTitle)}
              <FontAwesomeIcon
                icon={isDropdownOpen ? faChevronDown : faChevronRight}
              />
            </div>
            {isDropdownOpen && (
              <div className="campaign-dropdown-menu">
                {dropdownItems.map((item) => (
                  <NavLink
                    key={item.url}
                    state={item.name}
                    to={Pages.Campaigns}
                    title={t(item.name)}
                    className={(navData) =>
                      navData.isActive
                        ? "dropdown-item-campaign"
                        : "dropdown-item-campaign"
                    }
                  >
                    {typeof item.icon === "string" ? (
                      <Icon name={item.icon} />
                    ) : (
                      <FontAwesomeIcon
                        className="fa-lg faicon"
                        icon={item.icon}
                      />
                    )}
                    {t(item.name)}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

NavigationSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ),
  dropdownTitle: PropTypes.string,
};
