import Button from "@client.components/Button";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import Dropdown from "@client.components/Dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllDevicesAsync, makeDevices } from "@client.pages/Devices/reducer";
import CustomDateRangePicker from "@client.components/CustomDateRangePicker";

const FilterBar = ({ onFilterChange }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { t } = useTranslation();

  console.log(startDate)
  console.log(endDate)

  return (
    <div className="search-bar">
      <div className="search-inputs">
        <CustomDateRangePicker 
          value={[startDate, endDate]}
          onChange={(range) => {
            setStartDate(range.start.date)
            setEndDate(range.end.date)
          }}
        />
      </div>
      <Button
        text={t("pages.Devices.DeviceCaptures.letsgo")}
        className="search-submit"
        onClick={() => {
          onFilterChange({
            endDate,
            startDate,
          });
        }}
      />
    </div>
  );
};

FilterBar.propTypes = {
  onFilterChange: PropTypes.func,
};

export default FilterBar;
