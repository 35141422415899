import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@client.components/Button";
import Dropdown_Scrollable from "@client.components/Dropdown_Scrollable";
import "./CustomDateRangePicker.scss";

const CustomDateRangePicker = ({
  dateFrom,
  dateTo,
  onChange,
  label,
  placeholder = "Select a range",
}) => {
  const [startDate, setStartDate] = useState(dateFrom);
  const [endDate, setEndDate] = useState(dateTo);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [leftMonth, setLeftMonth] = useState(new Date());
  const [rightMonth, setRightMonth] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );

  useEffect(() => {
    setStartDate(dateFrom);
    setEndDate(dateTo);
  }, [dateFrom, dateTo]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleDateSelection = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (startDate && !endDate && date > startDate) {
      setEndDate(date);
    } else {
      setStartDate(date);
    }
  };

  const navigateMonth = (direction, calendar) => {
    if (calendar === "left") {
      const newMonth = new Date(leftMonth);
      newMonth.setMonth(leftMonth.getMonth() + direction);
      setLeftMonth(newMonth);
    } else {
      const newMonth = new Date(rightMonth);
      newMonth.setMonth(rightMonth.getMonth() + direction);
      setRightMonth(newMonth);
    }
  };

  const clearRange = () => {
    setStartDate(null);
    setEndDate(null);
    setStartTime("00:00");
    setEndTime("00:00");
  };

  const applyRange = () => {
    setIsPopupOpen(false);
    if (onChange) {
      onChange({
        start: { date: startDate, time: startTime },
        end: { date: endDate, time: endTime },
      });
    }
  };

  const renderTimePicker = (timeValue, setTimeValue, label) => {
    const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
      const hours = String(Math.floor(i / 4)).padStart(2, "0");
      const minutes = String((i % 4) * 15).padStart(2, "0");
      return { time: `${hours}:${minutes}` };
    });

    return (
      <div className="time-picker">
        <Dropdown_Scrollable
          items={timeOptions}
          valueKey="time"
          labelKey="time"
          defaultValue={timeValue}
          onSelect={(value) => setTimeValue(value)}
          placeholder={`Select ${label} Time`}
        />
      </div>
    );
  };

  const renderCalendar = (viewMonth) => {
    const lastDayOfMonth = new Date(
      viewMonth.getFullYear(),
      viewMonth.getMonth() + 1,
      0
    );
    const days = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const currentDay = new Date(
        viewMonth.getFullYear(),
        viewMonth.getMonth(),
        i
      );
      const isSelected =
        (startDate && currentDay.getTime() === startDate.getTime()) ||
        (endDate && currentDay.getTime() === endDate.getTime()) ||
        (startDate &&
          endDate &&
          currentDay >= startDate &&
          currentDay <= endDate);

      days.push(
        <button
          key={i}
          className={`calendar-day ${isSelected ? "selected" : ""}`}
          onClick={() => handleDateSelection(currentDay)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="calendar-grid">
        {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
          <div key={index} className="calendar-day-name">
            {day}
          </div>
        ))}
        {days}
      </div>
    );
  };

  return (
    <div className="date-range-picker">
      {label && <span className="label">{label}</span>}
      <div className="daterangepicker-container">
        <input
          type="text"
          placeholder={placeholder}
          readOnly
          value={`${startDate ? startDate.toLocaleDateString() : ""} ${
            startTime
          } - ${endDate ? endDate.toLocaleDateString() : ""} ${endTime}`}
          onClick={togglePopup}
          className="daterangepicker-input"
        />
        {isPopupOpen && (
          <div className="daterangepicker-popup">
            <div className="calendar-container">
              <div className="calendar">
                <div className="calendar-header">
                  <button
                    onClick={() => navigateMonth(-1, "left")}
                    className="calendar-nav"
                  >
                    {"<"}
                  </button>
                  <span className="calendar-title">
                    {leftMonth.toLocaleDateString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    onClick={() => navigateMonth(1, "left")}
                    className="calendar-nav"
                  >
                    {">"}
                  </button>
                </div>
                {renderCalendar(leftMonth)}
                {renderTimePicker(startTime, setStartTime, "Start")}
              </div>
              <hr style={{ marginLeft: "20px", opacity: 0.3, marginTop: -15 }} />
              <div className="calendar">
                <div className="calendar-header">
                  <button
                    onClick={() => navigateMonth(-1, "right")}
                    className="calendar-nav"
                  >
                    {"<"}
                  </button>
                  <span className="calendar-title">
                    {rightMonth.toLocaleDateString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    onClick={() => navigateMonth(1, "right")}
                    className="calendar-nav"
                  >
                    {">"}
                  </button>
                </div>
                {renderCalendar(rightMonth)}
                {renderTimePicker(endTime, setEndTime, "End")}
              </div>
            </div>

            <div className="daterangepicker-footer">
              <Button
                text="Clear"
                onClick={clearRange}
                className="Button alternative"
              />
              <Button text="Apply" onClick={applyRange} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CustomDateRangePicker.defaultProps = {
  label: "",
};

CustomDateRangePicker.propTypes = {
  label: PropTypes.string,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default React.memo(CustomDateRangePicker);











//time version A


// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import Button from "@client.core.components/Button";
// import "./CustomDateRangePicker.scss";
// import Dropdown from "@client.core.components/Dropdown";
// const CustomDateRangePicker = ({
//   dateFrom,
//   dateTo,
//   onChange,
//   label,
//   placeholder = "Select a range",
// }) => {
//   const [startDate, setStartDate] = useState(dateFrom);
//   const [endDate, setEndDate] = useState(dateTo);
//   const [startTime, setStartTime] = useState("00:00");
//   const [endTime, setEndTime] = useState("00:00");
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [leftMonth, setLeftMonth] = useState(new Date());
//   const [rightMonth, setRightMonth] = useState(
//     new Date(new Date().setMonth(new Date().getMonth() + 1))
//   );

//   useEffect(() => {
//     setStartDate(dateFrom);
//     setEndDate(dateTo);
//   }, [dateFrom, dateTo]);

//   const togglePopup = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };

//   const handleDateSelection = (date) => {
//     if (!startDate || (startDate && endDate)) {
//       setStartDate(date);
//       setEndDate(null);
//     } else if (startDate && !endDate && date > startDate) {
//       setEndDate(date);
//     } else {
//       setStartDate(date);
//     }
//   };

//   const navigateMonth = (direction, calendar) => {
//     if (calendar === "left") {
//       const newMonth = new Date(leftMonth);
//       newMonth.setMonth(leftMonth.getMonth() + direction);
//       setLeftMonth(newMonth);
//     } else {
//       const newMonth = new Date(rightMonth);
//       newMonth.setMonth(rightMonth.getMonth() + direction);
//       setRightMonth(newMonth);
//     }
//   };

//   const clearRange = () => {
//     setStartDate(null);
//     setEndDate(null);
//     setStartTime("00:00");
//     setEndTime("00:00");
//   };

//   const applyRange = () => {
//     setIsPopupOpen(false);
//     if (onChange) {
//       onChange({
//         start: { date: startDate, time: startTime },
//         end: { date: endDate, time: endTime },
//       });
//     }
//   };

//   const renderTimePicker = (timeValue, setTimeValue) => {
//     const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
//       const hours = String(Math.floor(i / 4)).padStart(2, "0");
//       const minutes = String((i % 4) * 15).padStart(2, "0");
//       return `${hours}:${minutes}`;
//     });

//     return (
//       <div className="time-picker">
//         <Dropdown items={timeOptions} valueKey="time" labelKey="time" />
        
//         <select
//           value={timeValue}
//           onChange={(e) => setTimeValue(e.target.value)}
//         >
//           {timeOptions.map((time) => (
//             <option key={time} value={time}>
//               {time}
//             </option>
//           ))}
//         </select>
//       </div>
//     );
//   };

//   const renderCalendar = (viewMonth) => {
//     const lastDayOfMonth = new Date(
//       viewMonth.getFullYear(),
//       viewMonth.getMonth() + 1,
//       0
//     );
//     const days = [];
//     for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
//       const currentDay = new Date(
//         viewMonth.getFullYear(),
//         viewMonth.getMonth(),
//         i
//       );
//       const isSelected =
//         (startDate && currentDay.getTime() === startDate.getTime()) ||
//         (endDate && currentDay.getTime() === endDate.getTime()) ||
//         (startDate &&
//           endDate &&
//           currentDay >= startDate &&
//           currentDay <= endDate);

//       days.push(
//         <button
//           key={i}
//           className={`calendar-day ${isSelected ? "selected" : ""}`}
//           onClick={() => handleDateSelection(currentDay)}
//         >
//           {i}
//         </button>
//       );
//     }

//     return (
//       <div className="calendar-grid">
//         {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
//           <div key={index} className="calendar-day-name">
//             {day}
//           </div>
//         ))}
//         {days}
//       </div>
//     );
//   };

//   return (
//     <div className="date-range-picker">
//       {label && <span className="label">{label}</span>}
//       <div className="daterangepicker-container">
//         <input
//           type="text"
//           placeholder={placeholder}
//           readOnly
//           value={`${startDate ? startDate.toLocaleDateString() : ""} ${
//             startTime
//           } - ${endDate ? endDate.toLocaleDateString() : ""} ${endTime}`}
//           onClick={togglePopup}
//           className="daterangepicker-input"
//         />
//         {isPopupOpen && (
//           <div className="daterangepicker-popup">
//             <div className="calendar-container">
//               <div className="calendar">
//                 <div className="calendar-header">
//                   <button
//                     onClick={() => navigateMonth(-1, "left")}
//                     className="calendar-nav"
//                   >
//                     {"<"}
//                   </button>
//                   <span className="calendar-title">
//                     {leftMonth.toLocaleDateString("en-US", {
//                       month: "long",
//                       year: "numeric",
//                     })}
//                   </span>
//                   <button
//                     onClick={() => navigateMonth(1, "left")}
//                     className="calendar-nav"
//                   >
//                     {">"}
//                   </button>
//                 </div>
//                 {renderCalendar(leftMonth)}
//                 {renderTimePicker(startTime, setStartTime)}
//               </div>
//               <hr style={{ marginLeft: "20px", opacity: 0.3, marginTop: -15 }} />
//               <div className="calendar">
//                 <div className="calendar-header">
//                   <button
//                     onClick={() => navigateMonth(-1, "right")}
//                     className="calendar-nav"
//                   >
//                     {"<"}
//                   </button>
//                   <span className="calendar-title">
//                     {rightMonth.toLocaleDateString("en-US", {
//                       month: "long",
//                       year: "numeric",
//                     })}
//                   </span>
//                   <button
//                     onClick={() => navigateMonth(1, "right")}
//                     className="calendar-nav"
//                   >
//                     {">"}
//                   </button>
//                 </div>
//                 {renderCalendar(rightMonth)}
//                 {renderTimePicker(endTime, setEndTime)}
//               </div>
//             </div>

//             <div className="daterangepicker-footer">
//               <Button
//                 text="Clear"
//                 onClick={clearRange}
//                 className="Button alternative"
//               />
//               <Button text="Apply" onClick={applyRange} />
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// CustomDateRangePicker.defaultProps = {
//   label: "",
// };

// CustomDateRangePicker.propTypes = {
//   label: PropTypes.string,
//   dateFrom: PropTypes.instanceOf(Date),
//   dateTo: PropTypes.instanceOf(Date),
//   onChange: PropTypes.func.isRequired,
//   placeholder: PropTypes.string,
// };

// export default React.memo(CustomDateRangePicker);


















// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import './CustomDateRangePicker.scss';
// import Button from '@client.components/Button';

// const CustomDateRangePicker = ({ value = [null, null], onChange, placeholder = 'Select a range',label  }) => {
//   const [startDate, setStartDate] = useState(value[0]);
//   const [endDate, setEndDate] = useState(value[1]);
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [leftMonth, setLeftMonth] = useState(new Date());
//   const [rightMonth, setRightMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));

//   useEffect(() => {
//     // Sync with parent-provided values if they change
//     setStartDate(value[0]);
//     setEndDate(value[1]);
//   }, [value]);

//   const togglePopup = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };

//   const handleDateSelection = (date) => {
//     if (!startDate || (startDate && endDate)) {
//       setStartDate(date);
//       setEndDate(null);
//     } else if (startDate && !endDate && date > startDate) {
//       setEndDate(date);
//     } else {
//       setStartDate(date);
//     }
//   };

//   const navigateMonth = (direction, calendar) => {
//     if (calendar === 'left') {
//       const newMonth = new Date(leftMonth);
//       newMonth.setMonth(leftMonth.getMonth() + direction);
//       setLeftMonth(newMonth);
//     } else {
//       const newMonth = new Date(rightMonth);
//       newMonth.setMonth(rightMonth.getMonth() + direction);
//       setRightMonth(newMonth);
//     }
//   };

//   const clearRange = () => {
//     setStartDate(null);
//     setEndDate(null);
//   };

//   const applyRange = () => {
//     setIsPopupOpen(false);
//     if (onChange) {
//       onChange([startDate, endDate]);
//     }
//   };

//   const renderCalendar = (viewMonth) => {
//     const lastDayOfMonth = new Date(viewMonth.getFullYear(), viewMonth.getMonth() + 1, 0);
//     const days = [];
//     for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
//       const currentDay = new Date(viewMonth.getFullYear(), viewMonth.getMonth(), i);
//       const isSelected =
//         (startDate && currentDay.getTime() === startDate.getTime()) ||
//         (endDate && currentDay.getTime() === endDate.getTime()) ||
//         (startDate && endDate && currentDay >= startDate && currentDay <= endDate);

//       days.push(
//         <button
//           key={i}
//           className={`calendar-day ${isSelected ? 'selected' : ''}`}
//           onClick={() => handleDateSelection(currentDay)}
//         >
//           {i}
//         </button>
//       );
//     }

//     return (
//       <div className="calendar-grid">
//         {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
//           <div key={index} className="calendar-day-name">{day}</div>
//         ))}
//         {days}
//       </div>
//     );
//   };

//   return (
//     <div className="daterangepicker-container">
//        {label && <span className="label">{label}</span>}
//       <input
//         type="text"
//         placeholder={placeholder}
//         readOnly
//         value={`${startDate ? startDate.toLocaleDateString() : ''} - ${endDate ? endDate.toLocaleDateString() : ''}`}
//         onClick={togglePopup}
//         className="daterangepicker-input"
//       />
//       {isPopupOpen && (
//         <div className="daterangepicker-popup">
//           <div className="calendar-container">
//             <div className="calendar">
//               <div className="calendar-header">
//                 <button onClick={() => navigateMonth(-1, 'left')} className="calendar-nav">Prev</button>
//                 <span className="calendar-title">
//                   {leftMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
//                 </span>
//                 <button onClick={() => navigateMonth(1, 'left')} className="calendar-nav">Next</button>
//               </div>
//               {renderCalendar(leftMonth)}
//             </div>
//             <hr style={{ marginLeft: '20px' }} />
//             <div className="calendar">
//               <div className="calendar-header">
//                 <button onClick={() => navigateMonth(-1, 'right')} className="calendar-nav">Prev</button>
//                 <span className="calendar-title">
//                   {rightMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
//                 </span>
//                 <button onClick={() => navigateMonth(1, 'right')} className="calendar-nav">Next</button>
//               </div>
//               {renderCalendar(rightMonth)}
//             </div>
//           </div>

//           <div className="daterangepicker-footer">
//             <Button text="Clear" onClick={clearRange} />
//             <Button text="Apply" onClick={applyRange} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// CustomDateRangePicker.propTypes = {
//   value: PropTypes.array,
//   label: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   placeholder: PropTypes.string,
// };

// export default CustomDateRangePicker;

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import './CustomDateRangePicker.scss';
// import Button from '@client.components/Button';

// const CustomDateRangePicker = ({ value, onChange, placeholder = 'Select a range' }) => {
//   const [startDate, setStartDate] = useState(value[0] || null);
//   const [endDate, setEndDate] = useState(value[1] || null);
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [leftMonth, setLeftMonth] = useState(new Date());
//   const [rightMonth, setRightMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));

//   // Toggle date picker popup
//   const togglePopup = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };

//   // Handle date selection logic
//   const handleDateSelection = (date) => {
//     if (!startDate || (startDate && endDate)) {
//       setStartDate(date);
//       setEndDate(null);
//     } else if (startDate && !endDate && date > startDate) {
//       setEndDate(date);
//     } else {
//       setStartDate(date);
//     }
//   };

//   // Navigation for calendar months
//   const navigateMonth = (direction, calendar) => {
//     if (calendar === 'left') {
//       const newMonth = new Date(leftMonth);
//       newMonth.setMonth(leftMonth.getMonth() + direction);
//       setLeftMonth(newMonth);
//     } else {
//       const newMonth = new Date(rightMonth);
//       newMonth.setMonth(rightMonth.getMonth() + direction);
//       setRightMonth(newMonth);
//     }
//   };

//   // Clear selected range
//   const clearRange = () => {
//     setStartDate(null);
//     setEndDate(null);
//   };

//   // Apply selected date range and close popup
//   const applyRange = () => {
//     setIsPopupOpen(false);
//     if (onChange) onChange([startDate, endDate]);
//   };

//   // Render days in calendar
//   const renderCalendar = (viewMonth) => {
//     const lastDay = new Date(viewMonth.getFullYear(), viewMonth.getMonth() + 1, 0).getDate();
//     const days = [];
//     for (let i = 1; i <= lastDay; i++) {
//       const currentDay = new Date(viewMonth.getFullYear(), viewMonth.getMonth(), i);
//       const isSelected = (startDate && currentDay.getTime() === startDate.getTime()) || (endDate && currentDay.getTime() === endDate.getTime());
//       const isBetween = startDate && endDate && currentDay >= startDate && currentDay <= endDate;
//       days.push(
//         <button
//           key={i}
//           className={`day ${isSelected ? 'selected' : ''} ${isBetween ? 'between' : ''}`}
//           onClick={() => handleDateSelection(currentDay)}
//         >
//           {i}
//         </button>
//       );
//     }
//     return (
//       <div className="calendar-grid">
//         {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
//           <div key={index} className="day-name">{day}</div>
//         ))}
//         {days}
//       </div>
//     );
//   };

//   // Update startDate and endDate when value prop changes
//   useEffect(() => {
//     setStartDate(value[0] || null);
//     setEndDate(value[1] || null);
//   }, [value]);

//   return (
//     <div className="daterangepicker-container">
//       <input
//         type="text"
//         placeholder={placeholder}
//         readOnly
//         value={`${startDate ? startDate.toLocaleDateString() : ''} - ${endDate ? endDate.toLocaleDateString() : ''}`}
//         onClick={togglePopup}
//         className="daterangepicker-input"
//       />
//       {isPopupOpen && (
//         <div className="daterangepicker-popup">
//           <div className="calendar-container">
//             <div className="calendar">
//               <div className="calendar-header">
//                 <button onClick={() => navigateMonth(-1, 'left')} className="calendar-nav">Prev</button>
//                 <span className="calendar-title">
//                   {leftMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
//                 </span>
//                 <button onClick={() => navigateMonth(1, 'left')} className="calendar-nav">Next</button>
//               </div>
//               {renderCalendar(leftMonth)}
//             </div>
//             <hr style={{ marginLeft: '20px' }} />
//             <div className="calendar">
//               <div className="calendar-header">
//                 <button onClick={() => navigateMonth(-1, 'right')} className="calendar-nav">Prev</button>
//                 <span className="calendar-title">
//                   {rightMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
//                 </span>
//                 <button onClick={() => navigateMonth(1, 'right')} className="calendar-nav">Next</button>
//               </div>
//               {renderCalendar(rightMonth)}
//             </div>
//           </div>
//           <div className="daterangepicker-footer">
//             <Button text="Clear" onClick={clearRange} />
//             <Button text="Apply" onClick={applyRange} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// CustomDateRangePicker.propTypes = {
//   value: PropTypes.array,
//   onChange: PropTypes.func.isRequired,
//   placeholder: PropTypes.string,
// };

// CustomDateRangePicker.defaultProps = {
//   value: [null, null],
// };

// export default CustomDateRangePicker;

// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import './CustomDateRangePicker.scss';
// import Button from '@client.components/Button';

// const CustomDateRangePicker = ({ placeholder = 'Select a range' }) => {
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [leftMonth, setLeftMonth] = useState(new Date());
//   const [rightMonth, setRightMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));

//   const togglePopup = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };

//   const handleDateSelection = (date) => {
//     if (!startDate || (startDate && endDate)) {
//       setStartDate(date);
//       setEndDate(null);
//     } else if (startDate && !endDate && date > startDate) {
//       setEndDate(date);
//     } else {
//       setStartDate(date);
//     }
//   };

//   const navigateMonth = (direction, calendar) => {
//     if (calendar === 'left') {
//       const newMonth = new Date(leftMonth);
//       newMonth.setMonth(leftMonth.getMonth() + direction);
//       setLeftMonth(newMonth);
//     } else {
//       const newMonth = new Date(rightMonth);
//       newMonth.setMonth(rightMonth.getMonth() + direction);
//       setRightMonth(newMonth);
//     }
//   };

//   const clearRange = () => {
//     setStartDate(null);
//     setEndDate(null);
//   };

//   const applyRange = () => {
//     setIsPopupOpen(false);
//   };

//   const renderCalendar = (viewMonth) => {
//     const firstDayOfMonth = new Date(viewMonth.getFullYear(), viewMonth.getMonth(), 1);
//     const lastDayOfMonth = new Date(viewMonth.getFullYear(), viewMonth.getMonth() + 1, 0);
//     const days = [];
//     for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
//       const currentDay = new Date(viewMonth.getFullYear(), viewMonth.getMonth(), i);
//       const isSelected =
//         (startDate && currentDay.getTime() === startDate.getTime()) ||
//         (endDate && currentDay.getTime() === endDate.getTime()) ||
//         (startDate && endDate && currentDay >= startDate && currentDay <= endDate);

//       days.push(
//         <button
//           key={i}
//           className={`calendar-day ${isSelected ? 'selected' : ''}`}
//           onClick={() => handleDateSelection(currentDay)}
//         >
//           {i}
//         </button>
//       );
//     }

//     return (
//       <div className="calendar-grid">
//         {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
//           <div key={index} className="calendar-day-name">{day}</div>
//         ))}
//         {days}
//       </div>
//     );
//   };

//   return (
//     <div className="daterangepicker-container">
//       <input
//         type="text"
//         placeholder={placeholder}
//         readOnly
//         value={`${startDate ? startDate.toLocaleDateString() : ''} - ${endDate ? endDate.toLocaleDateString() : ''}`}
//         onClick={togglePopup}
//         className="daterangepicker-input"

//       />
//       {isPopupOpen && (
//         <div className="daterangepicker-popup">
//           <div className="calendar-container">
//             <div className="calendar">
//               <div className="calendar-header">
//                 <button onClick={() => navigateMonth(-1, 'left')} className="calendar-nav">Prev</button>
//                 <span className="calendar-title">
//                   {leftMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
//                 </span>
//                 <button onClick={() => navigateMonth(1, 'left')} className="calendar-nav">Next</button>
//               </div>
//               {renderCalendar(leftMonth)}
//             </div>
//             <hr
//             // applay left margin to the line
//             style={{marginLeft: '20px'}}
//             />
//             <div className="calendar">
//               <div className="calendar-header">
//                 <button onClick={() => navigateMonth(-1, 'right')} className="calendar-nav">Prev</button>
//                 <span className="calendar-title">
//                   {rightMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
//                 </span>
//                 <button onClick={() => navigateMonth(1, 'right')} className="calendar-nav">Next</button>
//               </div>
//               {renderCalendar(rightMonth)}
//             </div>
//           </div>

//           <div className="daterangepicker-footer">
//             <Button text="Clear" onClick={clearRange}  />
//             <Button text="Apply" onClick={applyRange}  />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// CustomDateRangePicker.propTypes = {
//   placeholder: PropTypes.string,
// };

// export default CustomDateRangePicker;
