import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./Icon.scss";

const Icon = ({ name, className, ...rest }) => {
  return (
    <span className={classNames(`icon-${name}`, className)} {...rest}></span>
  );
};

Icon.defaultProps = {};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;
