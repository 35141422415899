export default class EventEmitterService {
  constructor() {
    this.events = {};
  }

  on(event, listener, unique) {
    if (unique) {
      this.removeAllListeners(event);
    }

    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  emit(event, args) {
    if (this.events[event]) {
      this.events[event].forEach((listener) => {
        listener(args);
      });
    }
  }

  removeAllListeners(event) {
    if (event) {
      delete this.events[event];
    } else {
      this.events = {};
    }
  }
}
