import Title from "@client.components/Title";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FilterBar from "./FilterBar";
import Viewer from "./Viewer";
import ServiceProvider from "@client.services/provider";
import Map from "./Map";
import AgeChart from "./AgeChart";
import GoogleMap from "@client.components/GoogleMap";
import Loader from "@client.components/Loader";
import Summary from "./Summary";
import Statistics from "./Statistics";
import "./DataAnalyzer.scss";
import {
  fetchDeviceCapturesAsync,
  makeCaptures,
  clearDeviceCaptures,
} from "@client.pages/Devices/reducer";
import NewDataTablePagination from "@client.components/NewDataTablePagination";
import SvgIcon from "@client.components/SvgIcon";
import TimelineItem from "./TimelineItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import Pages from "@client.enums/pages";

const ITEMS_PER_PAGE = 10;

const DataAnalyzer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const captures = useSelector(makeCaptures);
  const prevFilterRef = useRef(null);

  const [selectedFrame, setSelectedFrame] = useState('');
  const [data, setData] = useState({ results: [], count: 0 });
  const [capturesLoading, setCapturesLoading] = useState(false);
  const [analyzerData, setAnalyzerData] = useState(null);
  const [analyzerLoading, setAnalyzerLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // const [captures, setCaptures] = useState([])

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    
    const filterChanged = JSON.stringify(prevFilterRef.current) !== JSON.stringify(filter);

    if (filterChanged) {
      // Clear the existing captures if the filter changes
      dispatch(clearDeviceCaptures());
    }

    if (currentPage === 1) {
      setCapturesLoading(true);
    } else {
      setIsLoadingMore(true); // Show loader for additional fetches
    }

    dispatch(
      fetchDeviceCapturesAsync({
        ...filter,
        limit: ITEMS_PER_PAGE,
        offset,
      })
    )
      .finally(() => {
        setCapturesLoading(false);
        setIsLoadingMore(false);
      });

    prevFilterRef.current = filter;

  }, [currentPage, filter, dispatch]);

  useEffect(() => {
    if (selectedFrame) {
      const fetchAnalyzerData = async () => {
        setAnalyzerLoading(true);
        try {
          const response = await ServiceProvider.DataAnalyzer.getDataAnalyser(selectedFrame.Id)
          setAnalyzerData(response?.data.dataAnalyser);
        } catch (error) {
          console.error("Error fetching analyzer data: ", error);
        } finally {
          setAnalyzerLoading(false);
        }
      };

      fetchAnalyzerData();
    }
  }, [selectedFrame]);

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update current page
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
  
    // Check if the user has scrolled near the bottom
    if (scrollHeight - scrollTop <= clientHeight * 1.2 && !isLoadingMore) {
      setIsLoadingMore(true);
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="navigation-bar" data-testid="NavigationBar">
        <div className="nav-top-bar">
          <div className="logo-container">
            <SvgIcon
              name="logo"
              className="logo"
            />
            <div className= 'logout' onClick={() => navigate(Pages.Logout)}>
              <FontAwesomeIcon icon={faRightFromBracket} />
              <span>{t("components.ProfileMenu.logout")}</span>
            </div>
          </div>
          <FilterBar onFilterChange={setFilter} />
          <div className="aggregator">
            <div className="aggregator-title">
              Found {captures.count} slots with total of
            </div>
            <div className="aggregator-values">
              <div className="aggregator-value">{captures.peopleDetected || 0}</div>
              <div className="aggregator-value">{captures.views || 0}</div>
              <div className="aggregator-value">{captures.impressions || 0}</div>
            </div>
            <div className="aggregator-values">
              <div className="aggregator-key">People</div>
              <div className="aggregator-key">Views</div>
              <div className="aggregator-key">Impressions</div>
            </div>
          </div>
        </div>
        <div className="captures-container" onScroll={handleScroll}>
          <div className="captures-list">
            {captures.results.map((capture) => (
              <TimelineItem
                item={capture}
                key={capture.Id}
                isSelected={selectedFrame?.Id === capture.Id}
                onClick={
                  capture.status === 5 // Restrict clicks to "Processed" items only
                    ? () => setSelectedFrame(capture)
                    : null
                }
                disabled={capture.status !== 5}
              />
            ))}
            {isLoadingMore && <div className="loader">Loading more...</div>}
          </div>
        </div>
      </div>
      <div className="main">
        <div className="data-analyzer page">
          {/* {capturesLoading ? (
            <Loader loading={true} />
          ) : (
            <>
              <Viewer data={captures.results} onSelectFrame={setSelectedFrame} />
              <div className="pagination" style={
                {marginBottom: "40px"}
              }>
                <span className="pagination-summary">
                  {`${(currentPage - 1) * ITEMS_PER_PAGE + 1}-${Math.min(
                    currentPage * ITEMS_PER_PAGE,
                    captures.count
                  )} of ${captures.count} items`}
                </span>
                <NewDataTablePagination
                  total={captures.count}
                  itemsPerPage={ITEMS_PER_PAGE}
                  onChange={handlePageChange} // Sync pagination
                  currentPage={currentPage} // Controlled by the state
                />
              </div>
            </>
          )} */}
          {analyzerLoading && <Loader loading={true} />}

          {selectedFrame && !analyzerLoading && analyzerData && (
            <>
              <Summary data={analyzerData} />
              <Statistics analyzerData={analyzerData} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DataAnalyzer;
