import BaseService from "@client.services/baseService";

export default class SystemLogService extends BaseService {
  async getAll({ limit, offset, startDate, endDate, search, searchOn }) {
    return super.post("", {
      query: `
      query($pagination: PaginationInputType) {
        systemLogs(pagination: $pagination) {
          count
          results {
            Id
            createdAt
            userId
            userFullName
            type
            description
            ipAddress
            entity
            entityId
          }
        }
      }
      `,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }


  

  async create(systemLog) {
    return super.post("", {
      query: `mutation($fields: SystemLogsInputType){
	createSystemLog(fields: $fields){
		message
		systemLog{
			description
			Id
			userId
			userFullName
			entity
			entityId
			type
		}
	}
}`,
      variables: {
        fields: {
          description:systemLog.description ,
          // description:systemLog.description || "hello",
          type:systemLog.type ,
          entity:systemLog.entity ,
          // entityId:"6705a06f9f60efb05af582e2",
          entityId:systemLog.entityId || "",
        },
      },
    });
  }




  // async create(systemLog) {
  //   return super.post("", {
  //     query: `mutation($fields: SystemLogsInputType){
  // 	createSystemLog(fields: $fields){
  // 		message
  // 		systemLog{

  // 			description
  // 		}
  // 	}
  // }`,
  //     variables: {
  //       fields: {
  //         userId:systemLog.userId,
  //         ipAddress:systemLog.ipAddress|| null,
  //         description:systemLog.description|| null,
  //         type:systemLog.type,
  //         entity:systemLog.entity || null,
  //         entityId:systemLog.entityId || null,
  //     },
  //     },
  //   });
  // }







}





// import BaseService from "@client.services/baseService";

// export default class SystemLogService extends BaseService {
//   async getAll({ limit, offset, startDate, endDate, search, searchOn }) {
//     console.log("Params sent to systemLogs API:", { limit, offset, startDate, endDate, search, searchOn });

//     const response = await super.post("", {
//       query: `query($pagination: PaginationInputType) {
//         systemLogs(pagination: $pagination) {
//           count
//           results {
//             Id
//             type
//             userId
//             isActive
//             ipAddress
//             createdAt
//             updatedAt
//             description
//             entity
//             entityId
//           }
//         }
//       }`,
//       variables: {
//         pagination: {
//           limit,
//           offset,
//           search,
//           searchOn,
//           startDate,
//           endDate,
//         },
//       },
//     });

//     console.log("API Response from systemLogs:", response);
//     return response;
//   }
// }
