import { get } from "lodash";
import BaseService from "@client.services/baseService";

export default class UserService extends BaseService {
  async login(emailAddress, password) {
    const result = await super.post("", {
      query: `mutation ($emailAddress: String!, $password: String!) {
	login(emailAddress: $emailAddress, password: $password) {
		token
		message
		profile {
			firstName
			lastName
			emailAddress
			role
		}
		account{
			companyName
			entity
		}
	}
}
`,
      variables: {
        emailAddress,
        password,
      },
    });

    return result;
  }

  async profile() {
    const response = await super.post("", {
      query: `query {
        accountInformation {
          username
          phoneNumber
          name
          lastName
          firstName
          entity
          emailAddress
          avatarUrl
          accountName
          accountId
          Id
        }
      }`,
    });
    const result = get(response, "data.accountInformation", null);
    if (!result) {
      // it will be processed in reducer.
      return response;
    } else {
      const { entity, accountName, ...profile } = result;
      return {
        data: {
          profile,
          account: {
            entity: parseInt(entity),
            accountName,
          },
        },
      };
    }
  }

  async getAll(
    { limit, offset, startDate, endDate, search, searchOn },
    accountId
  ) {
    return super.post("", {
      query: `query($accountId: String, $pagination: PaginationInputType){
        users(accountId: $accountId, pagination: $pagination){
          count
          results{
            Id
            avatarUrl
            firstName
            lastName
            emailAddress
            role
            createdAt
            lastLoginTime
            has2fa
            isActive
            phoneNumber
          }
        }
      }`,
      variables: {
        accountId,
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }

  // async getAll2({ limit, offset, startDate, endDate, role }) {
  //   return super.post("", {
  //     query: `query (
  //         $limit: Int
  //         $offset: Int
  //         $endDate: String
  //         $startDate: String
  //         $role: Int
  //       ) {
  //       fetchUsers (endDate: $endDate, limit: $limit, offset: $offset, role: $role, startDate: $startDate) {
  //         count
  //         results {
  //           emailAddress
  //           phoneNumber
  //           avatarUrl
  //           lookupId
  //           firstName
  //           fullName
  //           lastName
  //           role
  //           lastLoginTime
  //           selectedAccount {
  //             companyName
  //           }
  //         }
  //       }
  //     }`,
  //     variables: {
  //       limit,
  //       offset,
  //       startDate,
  //       endDate,
  //       role,
  //     },
  //   });
  // }

  async forgotPassword(emailAddress) {
    return super.post(`forgotPassword`, {
      emailAddress,
    });
  }

  async logout() {
    return super.post(`logout`);
  }

  async delete(userId) {
    return super.post("", {
      query: `mutation($id: String){
	deleteUser(id: $id)
  }`,
      variables: {
        id: userId,
      },
    });
  }

  async create(user) {
    return super.post("", {
      query: `mutation($id: String!, $field: UserInputType){
	createUser(id: $id, fields: $field){
		message
		user{
			Id
			emailAddress
			passwordHash
			phoneNumber
			firstName
			lastName
			has2fa
			completedOnboarding
			passwordHash
			lastLoginTime
			avatarUrl
			role
			accountId
		}
	}
}`,
      variables: {
        id: user.Id || "",
        field: {
          avatarUrl: user.avatarUrl || "",
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
          phoneNumber: user.phoneNumber,
          role: user.role,
        },
      },
    });
  }

  async create2(user) {
    return super.post("", {
      query: `mutation CreateUser($fields: UserInputType!) {
        createUser(fields: $fields) {
          message
        }
      }`,
      variables: {
        fields: user.fields,
      },
    });
  }

  async save(user) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $user: UserInputType){
        updateUser(id: $id, user: $user){
          message
        }
      }`,
      variables: {
        id: user.lookupId,
        user: user.fields,
      },
    });
  }

  async changePassword(userId, password) {
    return super.post("", {
      query: `mutation UpdateUser($id: String, $fields: UserInputType){
        updateUser(id: $id, fields: $fields){
          message
        }
      }`,
      variables: {
        id: userId,
        fields: {
          password: password,
        },
      },
    });
  }
}
