import BaseService from "@client.services/baseService";
import moment from "moment";

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomGender() {
  const genders = ["Male", "Female"];
  return genders[Math.floor(Math.random() * genders.length)];
}

function getRandomVideo() {
  const videos = [
    "https://core.roadwarez.net/serve/6af3eb42-42bf-43da-9713-cbf3a6e55391.mp4",
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
  ];
  return videos[Math.floor(Math.random() * videos.length)];
}

const pathCoordinates = [
  { lat: 40.785091, lng: -73.968285 },
  { lat: 40.785191, lng: -73.968385 },
  { lat: 40.785291, lng: -73.968485 },
  { lat: 40.785391, lng: -73.968585 },
  { lat: 40.785491, lng: -73.968685 },
  { lat: 40.785591, lng: -73.968785 },
  { lat: 40.785691, lng: -73.968885 },
  { lat: 40.785791, lng: -73.968985 },
  { lat: 40.785891, lng: -73.969085 },
  { lat: 40.785991, lng: -73.969185 },
];

function generateRecords(startAt) {
  const records = [];

  for (let i = 0; i < 10; i++) {
    const qty = getRandomInt(1, 100);
    const views = parseInt(qty * 0.7);
    const record = {
      date: startAt.add(1, "second").format("YYYY-MM-DD HH:mm:ss"),
      age: getRandomInt(18, 60),
      qty,
      gender: getRandomGender(),
      impressions: parseInt(qty * 0.3),
      views,
      peopleInArea: parseInt(qty * 1.2),
      ...pathCoordinates[i],
    };

    records.push(record);
  }

  return records;
}

export default class DataAnalyzerService extends BaseService {
  constructor() {
    super("dataAnalyzer", true);
  }

  async getDeviceCaptures({ campaignId, deviceId, startDate, endDate, limit, offset, search, searchOn }) {
    return super.post("", {
      query: `
         query (
          $campaignId: String
          $deviceId: String
          $startDate: String
          $endDate: String
					$pagination: PaginationInputType
        ) {
          captures(
            campaignId: $campaignId
            deviceId: $deviceId
            startDate: $startDate
            endDate: $endDate
						pagination: $pagination
          ) {
            count
            results {
                Id
							campaignId
							campaignMediaUrl
              endDate
              afterMediaUrl
              startDate
              status
            }
          }
        }
      `,
      variables: {
        campaignId,
        deviceId,
        startDate,
        endDate,
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate: startDate
            ? new Date(startDate).toISOString().slice(0, 10)
            : null,
          endDate: endDate
            ? new Date(endDate).toISOString().slice(0, 10)
            : null,
        },
      },
    });
  }

  async getDataAnalyser(captureId) {
    return super.post("", {
      query: `
        query($captureId: String){
          dataAnalyser(captureId: $captureId) {
            ageGraph {
              age
              female
              male
            }
            ageRangeFemaleHigh
            ageRangeFemaleLow
            ageRangeMaleHigh
            ageRangeMaleLow
            impressions
            peopleAgeCount {
              age
              quantity
            }
            ivpGraph {
              datetime
              impressions
              peopleDetected
              views
            }
            peopleDetected
            peopleFemale
            peopleMale
            playLocations {
              latitude
              longitude
            }
            views
          }
        }
      `,
      variables: { captureId },
    });
  }
}
