import validate from "validate.js";

validate.validators.passwordConfirmation = function (
  value,
  options,
  key,
  attributes
) {
  if (value !== attributes[options.field]) {
    return options.message;
  }
};

validate.validators.passwordComplexity = function (value, options) {
  if (!value) {
    return options.message || "Password cannot be empty";
  }

  if (value.length < 8 || value.length > 32) {
    return options.message || "Password must be between 8 and 32 characters";
  }

  if (!/[A-Z]/.test(value)) {
    return options.message || "Password must include an uppercase character";
  }

  if (!/\d/.test(value)) {
    return options.message || "Password must include a number";
  }

  return null; // No error
};

class BaseModel {
  constructor(msg) {
    this.validationMsgs = msg;
    this.validationRules = {};
  }

  validate = (validationRules = {}) => {
    const rules =
      Object.keys(this.validationRules).length === 0
        ? validationRules
        : this.validationRules;
    const validation =
      Object.keys(rules).length === 0
        ? {}
        : validate(this, rules, {
          fullMessages: false,
        });

    return validation || {};
  };
}

export default BaseModel;
