import DeviceStatuses from "@client.enums/deviceStatuses";
import BaseService from "@client.services/baseService";

export default class DeviceService extends BaseService {
  async getAll({ limit, offset, startDate, endDate, search, searchOn,isOnline }) {
    console.log("Sending to backend: ", { limit, offset, startDate, endDate, search, searchOn,isOnline });
    return super.post("", {
      query: `
        query($accountId: String, $pagination: PaginationInputType,$isOnline: Boolean){
	      devices(accountId: $accountId, pagination: $pagination,isOnline: $isOnline){
            count
            results{
              Id
              deviceName
              machineId
              deviceType
              vpnId
              isOnline
              createdAt
              updatedAt
            }
          }
        }`,
      variables: {
        accountId: "",
        pagination: {
          limit,
          offset,
          search,     
          searchOn,   
          startDate: startDate ? new Date(startDate).toISOString().slice(0, 10) : null,
          endDate: endDate ? new Date(endDate).toISOString().slice(0, 10) : null,
        },
        isOnline,
      },
    });
  }
  

  async getAll2({
    limit = 10,
    offset = 0,
    accountLookupId = "",
    startDate = "",
    endDate = "",
    filterType = "",
    isOnline = "",
  }) {
    const response = await super.post("", {
      query: `
        query (
          $account_lookup_id: String
          $start_date: String
          $end_date: String
          $filter_type: [Int]
          $is_online: Boolean
          $order_by: String
          $limit: Int
          $offset: Int
        ) {
          fetchDevices(
            accountLookupId: $account_lookup_id
            startDate: $start_date
            endDate: $end_date
            filterType: $filter_type
            isOnline: $is_online
            orderBy: $order_by
            limit: $limit
            offset: $offset
          ) {
            count
            results {
              lookupId
              deviceName
              machineId
              isOnline
              vpnConfiguration
              defaultMedia
              country
              softwareVersion
              hardwareVersion
              account {
                lookupId
              }
              lastTelemetry {
                createdAt
                accelerometerX
                accelerometerY
                accelerometerZ
                gyroscopeX
                gyroscopeY
                gyroscopeZ
              }
            }
          }
        }
      `,
      variables: {
        account_lookup_id: accountLookupId || "",
        start_date: startDate || "",
        end_date: endDate || "",
        filter_type: filterType,
        is_online:
          isOnline === DeviceStatuses.All
            ? null
            : isOnline === DeviceStatuses.Online,
        order_by: "-last_message_date",
        limit,
        offset,
      },
    });

    // Log the response to check for issues
    return response;
  }

  async getAllByAccountId(accountLookupId = "") {
    const response = await super.post("", {
      query: `
        query (
          $accountLookupId: String
        ) {
          devices(accountId: $accountLookupId) {
            results {
              deviceName
              deviceType
              hardwareVersion
              softwareVersion
              timezone
              vpnId
              isOnline
              isActive
              installationReady
              lastMessageDate
              machineId
              updatedAt
              Id
              accountId
              assignedToId
              createdAt
            }
          }
        }
      `,
      variables: {
        accountId: accountLookupId || "",
      },
    });

    return response;
  }
  async getApprovals() {
    const response = await super.post("", {
      query: `
        query{
          fetchPendingDevices{
            lookupId
            createdAt
            machineId
            verificationCode
            country
            timezoneCode
          }
        }
    `,
    });
    return response;
  }
  async approveDevice(lookupId) {
    return super.post("", {
      query: `mutation configureDeviceApproval($lookupId: String){
      configureDeviceApproval(lookupId: $lookupId){
      message
      }
    } `,
      variables: {
        lookupId: lookupId,
      },
    });
  }

  async getById(id) {
    return super.post("", {
      query: `query($id: String){
	device(id: $id){
		Id
		deviceName
		machineId
		deviceType
	}
}`,
      variables: {
        id: id,
      },
    });
  }

  async getById2(id) {
    return super.post("", {
      query: `query($lookupId: String){
        fetchDevice(id: $lookupId) {
          lookupId
          createdAt
          isActive
          updatedAt
          deviceName
          machineId
          defaultMedia
          verificationCode
          timezoneCode
          hardwareVersion
          softwareVersion
          country
          lastTelemetry {
            battery
            temperature
            coresUtilization
            memoryUtilization
            totalCores
            totalDisk
            uptime
            longitude
            latitude
            updatedAt
            luxLight
          }
          vpn{
            lookupId
            ipAddress
            vpnServer{
              serverName
            }
            vpnDeviceSingle{
              deviceName
            }
          }
          assignedTo{
            fullName
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getDeviceCaptures({ campaignId, deviceId, startDate, endDate, limit, offset, search, searchOn }) {
    return super.post("", {
      query: `
         query (
          $startDate: String
          $endDate: String
					$pagination: PaginationInputType
        ) {
          captures(
            startDate: $startDate
            endDate: $endDate
						pagination: $pagination
          ) {
            count
            results {
                Id
							campaignId
							campaignMediaUrl
              endDate
              afterMediaUrl
              startDate
              status
            }
            views
            peopleDetected
            impressions
          }
        }
      `,
      variables: {
        campaignId,
        deviceId,
        startDate,
        endDate,
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate: startDate
            ? new Date(startDate).toISOString().slice(0, 10)
            : null,
          endDate: endDate
            ? new Date(endDate).toISOString().slice(0, 10)
            : null,
        },
      },
    });
  }

  async fetchDeviceTelemetry(limit, offset, lookupId) {
    return super.post("", {
      query: `query(
          $limit: Int
          $offset: Int
          $lookupId: String
          ) {
            fetchTelemetry(endDate: "", limit: $limit, lookupId: $lookupId, offset: $offset, startDate: "") {
              count
              results {
                battery
                coresUtilization
                createdAt
                diskUtilization
                fullSpectrumIrVisibleLight
                isActive
                infraredLight
                latitude
                longitude
                lookupId
                luxLight
                memoryUtilization
                signal
                temperature
                totalCores
                totalDisk
                totalMemory
                updatedAt
                uptime
                visibleLight
                accelerometerX
                accelerometerY
                accelerometerZ
                gyroscopeZ
                gyroscopeY
                gyroscopeX
              }
            }
          }`,
      variables: {
        limit,
        offset,
        lookupId,
      },
    });
  }

  async create(device) {
    return super.post("", {
      query: `mutation($fields: DeviceInputType){
	createDevice(fields: $fields){
		message
		device{
			Id
			deviceName
			machineId
			deviceType
			installationReady
		}
	}
}`,
      variables: {
        fields: {
          deviceName: device.deviceName,
          deviceType: device.deviceType,
          machineId: device.machineId,
        },
      },
    });
  }

  async update(model) {
    return super.post("", {
      query: `mutation($id: String, $fields: DeviceInputType) {
        updateDevice(id: $id, fields: $fields) {
          message
          device {
            Id
            deviceName
            machineId
            deviceType
          }
        }
      }`,
      variables: {
        id: model.Id, // Use model.Id directly as required by your new query
        fields: { // Ensure the correct field name
          deviceName: model.deviceName || "", // Default empty string if value is null/undefined
          deviceType: model.deviceType || "",
          machineId: model.machineId || "", 
        },
      },
    });
  }
  

  async update2(model) {
    const { lookupId, ...deviceToUpdate } = model.fields;
    return super.post("", {
      query: `mutation updateDevice($lookupId: String, $fields: DeviceInputType) {
        updateDevice(fields: $fields, lookupId: $lookupId) {
          message
        }
      }`,
      variables: {
        fields: deviceToUpdate,
        lookupId: lookupId,
      },
    });
  }

  async updateDeviceMedia(lookupId, defaultMedia) {
    return super.post("", {
      query: `mutation updateDevice($id: String, $fields: DeviceInputType) {
        updateDevice(id: $id, fields: $fields) {
          message
        }
      }`,
      variables: {
        id: lookupId,
        fields: {
          defaultMedia: defaultMedia,
        },
      },
    });
  }

  async delete(Id) {
    console.log("deviceId from deleteDeviceAsync", Id);
    return super.post("", {
      query: `mutation($id: String){
	deleteDevice(id: $id)
}`,

      variables: {
        id: Id,
      },
    });
  }

  async delete2(deviceId) {
    return super.post("", {
      query: `mutation($id: String){
        deleteDevice(id: $id){
          message
        }
      } `,

      variables: {
        id: deviceId,
      },
    });
  }

  async flickrSignal(macAddress, signal) {
    return super.post("", {
      query: `mutation CloudFlicker($macAddress: String, $signal: String) {
        cloudFlicker(macAddress: $macAddress, signal: $signal) {
          message
        }
      }`,
      variables: {
        macAddress,
        signal,
      },
    });
  }
}
