import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import { Polygon } from "recharts";

const DEFAULT_LOCATIONS = { locations: [], loading: false };
const DEFAULT_ZONES = { data: [], loading: false };

const initialState = {
  locations: DEFAULT_LOCATIONS,
  selectedZone: null,
  selected: { zone: null }, // Initialize `selected` with `zone` as null
  zones: DEFAULT_ZONES,
  error: null,
  loading: false,
};


export const getAllZonesAsync = createAsyncThunk(
  "locations/getZones",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLocationsLoading(true));
    try {
      return await ServiceProvider.Zone.getZones();
    } finally {
      thunkAPI.dispatch(setLocationsLoading(false));
    }
  }
);

// TODO: rename
export const getAllLocationsAsync = createAsyncThunk(
  "locations/getAll",
  async () => {
    const result = await ServiceProvider.Zone.getAll();
    return result;
  }
);



export const getLocationByIdAsync = createAsyncThunk(
  "locations/getById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setLocationsLoading(true));
    try {
      const response = await ServiceProvider.Zone.getById(id);
      // console.log('API Data Response from backenddddd: ' , response)
      return response;
    } finally {
      thunkAPI.dispatch(setLocationsLoading(false));
    }
  }
);



export const createLocationAsync = createAsyncThunk(
  "locations/create",
  async (zone, thunkAPI) => {
    const resp = await ServiceProvider.Zone.create(zone);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllLocationsAsync());
    }
    return resp;
  }
);

export const updateLocationAsync = createAsyncThunk(
  "locations/update",
  async (zone, thunkAPI) => {
    const resp = await ServiceProvider.Zone.update(zone);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllLocationsAsync());
    }
    return resp;
  }
);

export const deleteLocationAsync = createAsyncThunk(
  "locations/delete",
  async (id, thunkAPI) => {
    const resp = await ServiceProvider.Zone.delete(id);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllLocationsAsync());
    }
    return resp;
  }
);

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    clearLocationsError: (state) => {
      state.error = null;
    },
    setLocationsLoading: (state, action) => {
      state.locations.loading = get(action, "payload", false);
    },
    setLoading: (state, action) => {
      state.loading = get(action, "payload", false);
    },
    setSelectedZone: (state, action) => {
      state.selectedZone = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLocationsAsync.pending, (state) => {
        state.zones.loading = true;
      })
      .addCase(getAllLocationsAsync.fulfilled, (state, action) => {
        state.zones.data = get(action, "payload.data.zones.results", []).map(
          (zone) => ({
            ...zone,
            polygon: zone.polygon ? JSON.parse(zone.polygon) : [],
          })
        );
        state.zones.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllLocationsAsync.rejected, (state, action) => {
        state.zones = { ...DEFAULT_ZONES };
        state.error = getResponseError(action);
      })
      .addCase(getAllZonesAsync.fulfilled, (state, action) => {
        state.zones.data = get(action, "payload.data.fetchZones", []);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllZonesAsync.rejected, (state, action) => {
        state.zones = { ...DEFAULT_ZONES };
        state.error = getResponseError(action);
      })

      .addCase(getLocationByIdAsync.fulfilled, (state, action) => {
        // Safely assign to state.selected.zone
        if (!state.selected) state.selected = { zone: null };
        state.selected.zone = get(action, "payload.data.zone", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getLocationByIdAsync.rejected, (state, action) => {
        // Reset `selected` to its default state on error
        state.selected = { zone: null };
        state.error = getResponseError(action);
      })

      .addCase(createLocationAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createLocationAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateLocationAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateLocationAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(deleteLocationAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(deleteLocationAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  },
});

export const {
  setLocationsLoading,
  clearLocationsError,
  setLoading,
  setSelectedZone,
} = locationsSlice.actions;

export const makeLocations = (state) => state.location.locations;
export const makeZones = (state) => {
  const zones = [...state.location.zones.data];

  const zoneMap = {};
  const hierarchy = [];

  zones.forEach((zone) => {
    zoneMap[zone.Id] = { ...zone, items: [] };
  });

  zones.forEach((zone) => {
    if (zone.parentZoneId) {
      if (zoneMap[zone.parentZoneId]) {
        zoneMap[zone.parentZoneId].items.push(zoneMap[zone.Id]);
      }
    } else {
      hierarchy.push(zoneMap[zone.Id]);
    }
  });
  return hierarchy.sort((a, b) => b.category - a.category);
};
export const makeLocationsError = (state) => state.location.error;
export const makeLocationsLoading = (state) => state.location.loading;
export const makeSelectedZone = (state) => state.location.selectedZone;

export default locationsSlice.reducer;
