import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Input.scss";

export const Input = ({
  id,
  value,
  type,
  className,
  onChange,
  placeholder,
  label,
  maxLength,
  optional,
  onKeyDown,
  readOnly,
}) => {
  return (
    <div className="input-container">
      {label && <span className="label">{label}</span>}
      <input
        type={type}
        id={id}
        className={classNames("input", className)}
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        onChange={(event) => {
          onChange && onChange(event.target.value);
        }}
        {...optional}
      />
    </div>
  );
};

Input.defaultProps = {
  value: "",
  label: "",
  type: "text",
  onKeyDown: undefined,
};

Input.propTypes = {
  type: PropTypes.string,
  maxLength: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  optional: PropTypes.any,
};
