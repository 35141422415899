import React, { useState, useEffect, useRef } from "react";

const useSvgComponent = (svgAssetPath) => {
  const [loaded, setLoaded] = useState(false);
  const ImportedSvgRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const loadSvg = async () => {
      if (!ImportedSvgRef.current) {
        const module = await import(`@client.images/${svgAssetPath}.svg`);
        ImportedSvgRef.current = module.ReactComponent;
      }
      if (isMounted) setLoaded(true);
    };

    loadSvg();

    return () => {
      isMounted = false;
    };
  }, [svgAssetPath]);

  const SvgComponent = (props) => {
    if (!loaded) {
      return <div></div>;
    }

    const ImportedSvg = ImportedSvgRef.current;
    return <ImportedSvg {...props} />;
  };

  return SvgComponent;
};

export default useSvgComponent;
