import React, { useEffect, useRef, useState } from "react";
import Button from "@client.components/Button";
import ServiceProvider from "@client.services/provider";
import Loader from "@client.components/Loader";
import classNames from "classnames";
import Icon from "@client.components/Icon";
import "./Modal.scss";

export const MODAL_EVENT = "modal-component";

const DEFAULT_SETTINGS = {
  show: false,
  title: "",
  className: "",
  component: null,
  withBodyRef: false,
  buttonSettings: {},
};

export const Modal = () => {
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ServiceProvider.EventEmitter.on(MODAL_EVENT, (payload) => {
      setSettings(payload);
    });
    return () => {
      ServiceProvider.EventEmitter.removeAllListeners(MODAL_EVENT);
    };
  }, []);

  useEffect(() => {
    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {});
  }, []);

  const modalBodyRef = useRef(null);
  const buttonRef = useRef(null);
  const buttonClick = async () => {
    setIsLoading(true);

    await settings.buttonSettings.onClick(
      modalBodyRef.current,
      buttonRef.current
    );

    setIsLoading(false);
  };

  return (
    settings.show && (
      <div className="modal-component">
        <div className="modal-component-backdrop"></div>
        <div
          className={classNames("modal-component-content", settings.className)}
        >
          <div className="modal-component-title">
            {settings.title}
            <Icon
              name="close"
              className="modal-component-close-button"
              onClick={() => setSettings(DEFAULT_SETTINGS)}
            />
          </div>
          <div className="modal-component-body">
            {isLoading && (
              <div className="load-holder">
                <Loader loading={true} />
              </div>
            )}
            {settings.withBodyRef
              ? React.cloneElement(settings.component, {
                ref: modalBodyRef,
              })
              : settings.component}
            {settings.buttonSettings && (
              <div className="modal-component-footer">
                <Button
                  {...settings.buttonSettings}
                  disabled={isLoading}
                  ref={buttonRef}
                  onClick={buttonClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};
