import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Password from "@client.components/Password";
import {
  getProfileAsync,
  makeLoginLoading,
  processLoginAsync,
} from "./reducer";
import Button from "@client.components/Button";
import Input from "@client.components/Input";
import Pages from "@client.enums/pages";
import LoginModel from "models/login";
import Checkbox from "@client.components/Checkbox";
import { validateModel } from "@client.utils/form";
import ServiceProvider from "@client.services/provider";
import LocalStorageKeys from "@client.enums/localStorageKeys";
import WelcomeCard from "@client.components/WelcomeCard/WelcomeCard";
import UnauthorizedPageCard from "@client.components/UnauthorizedPageCard/UnauthorizedPageCard";
import "./Login.scss";

const Login = () => {
  const isLoading = useSelector(makeLoginLoading);
  const { t } = useTranslation();
  const [model, setModel] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Token);
    if (token) {
      dispatch(getProfileAsync());
    }
  }, [dispatch]);

  useEffect(() => {
    setModel(
      new LoginModel(
        {},
        {
          password: t("pages.Login.validation.password"),
          email: t("pages.Login.validation.emailAddress"),
          invalidEmail: t("pages.Login.validation.invalidEmail"),
        }
      )
    );
  }, [t]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(new LoginModel({ ...model, [field]: v }, model.validationMsgs));
    },
    [model]
  );

  const login = useCallback(() => {
    const isValid = validateModel(model);

    if (isValid) {
      dispatch(processLoginAsync(model));
    }
  }, [dispatch, model]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div className="login">
      <WelcomeCard>
        <div className="welcome">{t("pages.Login.welcome")}</div>
      </WelcomeCard>
      <UnauthorizedPageCard isLoading={isLoading}>
        <div className="title">{t("pages.Login.title")}</div>
        <div className="description">{t("pages.Login.subTitle")}</div>
        <Input
          id="user"
          placeholder={t("pages.Login.placeholder.emailAddress")}
          value={model.email}
          onChange={(v) => changeModel(v, "email")}
          label={t("pages.Login.fieldset.emailAddress")}
          onKeyDown={handleKeyPress}
        />
        <Password
          id="pswrd"
          value={model.password}
          placeholder={t("pages.Login.placeholder.password")}
          onChange={(v) => changeModel(v, "password")}
          onKeyDown={handleKeyPress}
        />
        <div className="remember-container">
          <Checkbox
            label={t("pages.Login.rememberMe")}
            value={rememberMe}
            onChange={(v) => {
              setRememberMe(v);
            }}
          />
          <NavLink to={Pages.ForgotPassword}>
            {t("pages.Login.forgotPassword")}
          </NavLink>
        </div>
        <div className="btn">
          <Button text={t("pages.Login.button.login")} onClick={login} />
        </div>
      </UnauthorizedPageCard>
    </div>
  );
};

export default Login;
