import BaseService from "@client.services/baseService";

export default class AccountService extends BaseService {
  async getAll({ limit, offset, startDate, endDate, search, searchOn }) {
    return super.post("", {
      query: `query (
        $limit: Int
        $offset: Int
        $endDate: String
        $startDate: String
        $search: String
      ) {
        accounts(
            pagination: {endDate: $endDate, limit: $limit, offset: $offset, search: $search, startDate: $startDate}
        ) {
          count
          results
          {
            Id
            accountName
            entity
            logoUrl
            description
            companyNumber
            vatNumber
          }
        }
      }`,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }
}
