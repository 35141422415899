import ServiceProvider from "@client.services/provider";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

const DEFAULT_DASHBOARD = {
  activeCampaigns: null,
  dailyAdPlays: null,
  dailyAdViews: null,
  dailyBudgetSpent: null,
  deviceMap: [],
  deviceHeatmap: [],
  deviceLocations: [],
};

const initialState = {
  platformDashboard: DEFAULT_DASHBOARD,
  loading: false,
  error: null,
  filter: {
    isUnknown: true,
    isDynamic: true,
    isStatic: true,
    isOnline: true,
    isOffline: true,
    enableHeatmap: false,
    enableLocations: false,
    id: "",
  },
};

export const fetchPlatformDashboardAsync = createAsyncThunk(
  "dashboard/fetchPlatformDashboard",
  async (filter = {}) => {
    const platformDashboard = await Promise.all([
      ServiceProvider.Dashboard.getDashboardBoxes(),
    ]);

    return platformDashboard;
  }
);

// Create a slice for the reducer
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDashboardFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatformDashboardAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlatformDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        const response = action.payload || [];
        state.platformDashboard = DEFAULT_DASHBOARD;
        if (response.length > 0) {
          state.platformDashboard = get(
            response[0],
            "data.dashboardBoxes",
            DEFAULT_DASHBOARD
          );
        }
      })
      .addCase(fetchPlatformDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        state.platformDashboard = DEFAULT_DASHBOARD;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { setLoading, setError, setDashboardFilter } =
  dashboardSlice.actions;

// Export selector to retrieve platform dashboard data from the state
export const makeDashboardFilter = (state) => state.dashboard.filter;
export const selectPlatformDashboard = (state) =>
  state.dashboard.platformDashboard;
// Export selector to retrieve loading state from the state
export const selectLoading = (state) => state.dashboard.loading;
// Export selector to retrieve error state from the state
export const selectError = (state) => state.dashboard.error;

export default dashboardSlice.reducer;
