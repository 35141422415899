import React, { useState, createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import { makeProfile, setProfile } from "@client.pages/Login/reducer";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const user = useSelector(makeProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationToNavigate, setLocationToNavigate] = useState(Pages.DataAnalyzer);

  const value = useMemo(() => {
    const login = async (data) => {
      dispatch(setProfile(data));
      navigate(Pages.DashboardTeam);
    };

    const logout = () => {
      dispatch(setProfile(null));
      navigate(Pages.Login, { replace: true });
    };

    return {
      user,
      login,
      logout,
      locationToNavigate,
      setLocationToNavigate,
    };
  }, [user, locationToNavigate, dispatch, navigate]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
