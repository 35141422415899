import BaseModel from "./base";
import { get } from "lodash";

class LoginModel extends BaseModel {
  constructor(user = {}, msg) {
    super(msg);

    this.email = get(user, "email", "");
    this.password = get(user, "password", "");
    this.validationMsgs = msg;
    this.validationRules = {
      email: {
        presence: { allowEmpty: false, message: msg.email },
        email: { message: msg.invalidEmail },
      },
      password: {
        presence: { allowEmpty: false, message: msg.password },
      },
    };
  }
}

export default LoginModel;
