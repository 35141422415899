import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SvgIcon from "@client.components/SvgIcon";
import "./Button.scss";

export const Button = forwardRef(
  ({ text, icon, onClick, className, disabled }, ref) => {
    const [isDisabled, setIsDisabled] = useState(!!disabled);

    useImperativeHandle(ref, () => ({
      setDisabled: setIsDisabled,
    }));

    useEffect(() => {
      setIsDisabled(disabled);
    }, [disabled]);

    return (
      <div
        ref={ref}
        className={classNames("button", { disabled: isDisabled }, className)}
        onClick={disabled ? null : onClick}
      >
        {icon && <SvgIcon name={icon} />}
        {text && <span>{text}</span>}
      </div>
    );
  }
);

Button.displayName = "Button";

Button.propTypes = {
  normalIcon: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
