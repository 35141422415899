import React from "react";
import PropTypes from "prop-types";
import useSvgComponent from "@client.hooks/useSvgComponent";

const SvgIcon = ({ name, ...rest }) => {
  const Svg = useSvgComponent(name);
  
  if (Svg) {
    return <Svg {...rest} />;
  }
  return null;
};

SvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SvgIcon;
