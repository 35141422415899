import { useLocation, useParams } from "react-router-dom";
import { useMemo } from "react";

function useStableParams() {
  const params = useParams();
  return useMemo(() => params, [params]);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default useStableParams;
